@charset "UTF-8";
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;

//=============================================
// Project contact
//=============================================

.p-contact {
  min-height: 20rem;
  padding: get_vw(60) get_vw(20);
  background: $mainColor;
  color: $baseBg;

  @include mq(md) {
    min-height: 14rem;
    padding: 7.8125vw 0;
  }

  @include mq(lg) {
    min-height: 3rem;
    padding: 7.8125vw 0;
  }

  &__title {
    margin-bottom: get_vw(10);
    text-align: center;
    letter-spacing: 5px;
    font-size: get_vw(32);

    @include mq(md) {
      font-size: 4vw;
      mask-border: 2.667vw;
    }
  }

  &__form {
    @include mq(md) {
      width: 36.667vw;
      margin: 0 auto;
    }
  }

  &__select {
    position: relative;
    margin-bottom: 1rem;
    background: #fff;
    color: $baseColor;

    &:hover {
      cursor: pointer;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: get_vw(14);
      right: get_vw(10);
      width: get_vw(12);
      height: get_vw(12);
      border-top: 3px solid $baseColor;
      border-right: 3px solid $baseColor;
      transform: translateX(-50%) rotate(135deg);
      pointer-events: none;

      @include mq(md) {
        top: 1.5vw;
        right: 1vw;
        width: 1vw;
        height: 1vw;
      }
    }

    select {
      display: inline-block;
      vertical-align: unset;
      width: 100%;
      height: 100%;
      padding: 3.125vw;
      font-size: get_vw(18);
      border: none;
      -webkit-appearance: none;
      appearance: none;
      -moz-appearance: none;
      appearance: none;

      @include mq(md) {
        padding: 1.4vw;
        font-size: 1.2vw;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__input {
    &--group {
      input,
      textarea {
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 1rem;
        padding: 3.125vw;
        font-size: get_vw(18);

        @include mq(md) {
          padding: 1.4vw;
          font-size: 1.2vw;
        }
      }

      textarea {
        box-sizing: border-box;
        width: 100%;
        height: 12rem;
        margin-bottom: 1rem;
        padding: 3.125vw;
        font-size: get_vw(18);

        @include mq(md) {
          padding: 1vw 1.4vw;
          font-size: 1.2vw;
        }
      }
    }

    &--right {
      text-align: right;
    }
  }

  &__submitWrap {
    input[type="submit"][disabled] {
      border-radius: 0.5rem;
      background: $accentColor;
      opacity: 0.5;
      cursor: not-allowed;
      -webkit-appearance: none;
      appearance: none;
    }
  }

  &__submit {
    display: block;
    box-sizing: border-box;
    width: 100%;
    max-width: 38rem;
    height: 8rem;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    letter-spacing: 2px;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 8rem;
    border: none;
    border-radius: 0.5rem;
    background: $accentColor;
    background-image: -webkit-linear-gradient(left, transparent 50%, $baseBg 50%);
    background-image: linear-gradient(to right, transparent 50%, $baseBg 50%);
    background-size: 200% 100%;
    color: $mainColor;
    -webkit-transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
      color 0.5s ease 0s, background-color 0.5s ease;
    transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s, color 0.5s ease 0s,
      background-color 0.5s;

    &:hover {
      background-color: $baseBg;
      background-position: -100% 100%;
      cursor: pointer;
    }
  }

  &__privacy--check {
    margin-bottom: get_vw(40);
    font-size: get_vw(12);

    @include mq(md) {
      margin-bottom: 2.667vw;
      font-size: 1.2vw;
    }

    input {
      @include mq(md) {
        vertical-align: bottom;
        width: 1.333vw;
        height: 1.333vw;
      }

      &:hover {
        cursor: pointer;
      }
    }

    a {
      text-decoration: underline;
      color: $baseBg;
      transition: all 1s;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
