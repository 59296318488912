@charset "UTF-8";
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;

//=============================================
// Component br
//=============================================

.c-dsn {
  @include mq(md) {
    display: none;
  }
}

.c-dsb {
  display: none;

  @include mq(md) {
    display: block;
  }
}
