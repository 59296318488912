@charset "UTF-8";
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;

//=============================================
// Project logo
//=============================================

.p-logo {
  width: 100%;
  background: $subBg;

  @include mq(md) {
    display: flex;
    align-items: center;
  }

  &__wrap {
    position: relative;
    width: get_vw(240);
    margin: 0 auto;
    margin-bottom: get_vw(20);

    @include mq(md) {
      bottom: 2vw;
      left: 3vw;
      width: auto;
      margin-bottom: 2rem;
    }

    @include mq(lg) {
      bottom: 6vw;
      left: 4vw;
    }

    &--image {
      margin-bottom: get_vw(20);
      text-align: center;

      @include mq(md) {
        width: 29.533vw;
        margin-bottom: 2rem;
      }
    }

    &--capture {
      width: 100%;
      margin: 0 auto;
      letter-spacing: 0.02rem;
      font-size: get_vw(9.5);
      font-weight: bold;
      line-height: 1.5;
      color: $mainColor;

      @include mq(md) {
        font-size: 1.067vw;
      max-width: 29vw;

      }
    }
  }

  &__imgs {
    width: 100%;

    &--item {
      display: flex;
      align-items: center;
      // margin-bottom: get_vw(20);
    }

    &--item01 {
      position: relative;
      left: get_vw(45);
      width: get_vw(42);
      height: get_vw(42);
      border: none;
      border-radius: 100%;
      background: $accentColor;
      animation-name: updown;
      animation-duration: 0.8s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;

      @include mq(md) {
        width: 9vw;
        height: 9vw;
      }
    }

    &--item02 {
      position: relative;
      top: 3vw;
      left: 25vw;
      width: get_vw(30);
      height: get_vw(80);

      @include mq(md) {
        left: 22vw;
        width: 5.5vw;
        height: 10vw;
      }
    }

    &--item03 {
      position: relative;
      bottom: 1vw;
      left: 28vw;
      width: get_vw(50);
      height: get_vw(80);
      animation-name: tilt;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: alternate;

      @include mq(md) {
        bottom: 3vw;
        left: 24vw;
        width: 8vw;
        height: 13.5vw;
      }
    }

    &--item04 {
      position: relative;
      top: get_vw(30);
      left: get_vw(110);
      width: get_vw(120);
      height: get_vw(80);
      fill: $accentColor;

      @include mq(md) {
        width: 19.5vw;
        height: 13vw;
      }

      &--left {
        position: relative;
        right: get_vw(5);
        bottom: get_vw(5);
        width: get_vw(60);
        height: get_vw(30);

        @include mq(md) {
          width: 10vw;
          height: 6vw;
        }
      }

      &--right {
        width: get_vw(40);
        height: get_vw(60);

        @include mq(md) {
          position: relative;
          right: 1rem;
          width: 9vw;
          height: 12vw;
        }
      }
    }

    &--item05 {
      position: relative;
      bottom: get_vw(20);
      left: get_vw(120);
      width: get_vw(120);
      height: get_vw(80);

      @include mq(md) {
        left: 25.5vw;
        width: 23vw;
        height: 11.5vw;
      }
    }

    &--item06 {
      position: relative;
      left: get_vw(20);
      width: get_vw(100);
      height: get_vw(60);

      @include mq(md) {
        width: 15vw;
        height: 9.5vw;
      }
    }

    &--item07 {
      position: relative;
      bottom: get_vw(30);
      left: get_vw(45);
      width: get_vw(20);
      height: get_vw(42);
      animation-name: sideChange;
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: alternate;

      @include mq(md) {
        top: 2vw;
        left: -5vw;
        width: 3vw;
        height: 11.2vw;
        animation-name: sideChange_pc;
      }
    }

    &--item08 {
      position: relative;
      left: get_vw(140);
      width: get_vw(90);
      height: get_vw(70);
      animation-name: updown;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;

      @include mq(md) {
        left: 23vw;
        width: 13.5vw;
        height: 11vw;
      }
    }

    &--wrap01 {
      width: get_vw(90);

      @include mq(md) {
        width: 36vw;
      }
    }

    &--wrap02 {
      display: block;
      width: get_vw(90);
      height: get_vw(90);
    }
  }
}

@keyframes updown {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 30px);
  }
}

@keyframes tilt {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(20deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(0.8, 0.8);
  }

  25% {
    transform: scale(0.9, 0.9);
  }

  50% {
    transform: scale(1, 1);
  }

  75% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1.2, 1.2);
  }
}

@keyframes sideChange {
  0% {
    transform: translate(0, 0) rotateY(0deg);
    // transform: translate(0px,0);
  }

  20% {
    transform: translate(5px, 0) rotateY(36deg);
    // transform: translate(5px,0);
  }

  40% {
    transform: translate(10px, 0) rotateY(72deg);
    // transform: translate(5px,0);
  }

  60% {
    transform: translate(15px, 0) rotateY(108deg);
    // transform: translate(10px,0);
  }

  80% {
    transform: translate(20px, 0) rotateY(144deg);
    // transform: translate(15px,0);
  }

  100% {
    transform: translate(25px, 0) rotateY(180deg);
  }
}

@keyframes sideChange_pc {
  0% {
    transform: rotateY();
  }

  25% {
    transform: rotateY(30deg);
  }

  75% {
    transform: rotateY(150deg);
  }

  100% {
    left: 0.1px;
    transform: rotateY(180deg);
  }
}
