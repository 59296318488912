@charset "UTF-8";
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;

//=============================================
// Project support
//=============================================

.p-support {
  position: relative;
  margin-bottom: get_vw(100);
  padding-top: get_vw(60);

  @include mq(md) {
    margin-bottom: 6.667vw;
    padding-top: 8vw;
  }

  &__bgImg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  &__head {
    margin-bottom: get_vw(60);

    @include mq(md) {
      width: 66.667vw;
      margin: 0 auto 5.333vw;
    }
  }

  &__title {
    margin-bottom: get_vw(40);
    letter-spacing: 2px;
    font-family: Montserrat, bold;
    font-size: get_vw(32);
    line-height: 1.5;
    color: $mainColor;

    @include mq(md) {
      margin-bottom: 2.667vw;
      text-align: center;
      font-size: 3.733vw;
    }

    &--sub {
      margin-bottom: get_vw(20);
      text-align: center;
      font-family: Montserrat, bold;
      font-size: get_vw(26);
      line-height: 1.5;

      @include mq(md) {
        margin-bottom: 4.533vw;
        letter-spacing: 2.2px;
        font-size: 1.867vw;
      }
    }
  }

  &__list {
    margin-bottom: get_vw(60);
    list-style: none;

    @include mq(md) {
      margin-bottom: 4vw;
    }

    &--item {
      display: flex;
      margin-bottom: get_vw(40);

      @include mq(md) {
        margin-bottom: 2.667vw;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--itemText {
      width: get_vw(234.5);

      @include mq(md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
    }

    &--itemNum {
      position: relative;
      width: get_vw(80);
      height: get_vw(80);
      margin-right: get_vw(20);
      text-align: center;
      font-size: get_vw(30);
      font-weight: bold;
      font-style: italic;
      line-height: get_vw(80);
      border-radius: 100%;
      background: $accentColor;
      color: $baseBg;

      @include mq(md) {
        width: 7.575vw;
        height: 6.667vw;
        margin-right: 1.333vw;
        font-size: 3vw;
        line-height: 6.667vw;
      }

      span {
        position: relative;
        right: get_vw(3);

        @include mq(md) {
          right: 0.3vw;
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: get_vw(80);
        right: 0;
        left: 0;
        width: get_vw(5);
        height: get_vw(150);
        margin: auto;
        background-color: $accentColor;

        @include mq(md) {
          top: 6.667vw;
          width: 0.4vw;
          height: 5.333vw;
        }
      }

      &:nth-child(4) {
        overflow: hidden;
      }
    }

    &--itemTitle {
      margin-bottom: get_vw(20);
      font-size: get_vw(22);
      font-weight: bold;
      color: $mainColor;

      @include mq(md) {
        margin-bottom: 1.333vw;
        font-size: 1.467vw;
      }
    }

    &--itemContent {
      letter-spacing: 1.3px;
      font-size: get_vw(16);
      line-height: 2;

      @include mq(md) {
        min-height: 4rem;
        font-size: 1.067vw;
      }
    }
  }

  &__under {
    margin-bottom: get_vw(60);
    font-size: get_vw(12);
    line-height: 2rem;

    @include mq(md) {
      margin-bottom: 4vw;
      font-size: 0.8vw;
    }
  }

  &--hidden {
    overflow: hidden;
  }

  &__foot {
    padding: get_vw(60) get_vw(20);
    background: $subBg;

    @include mq(md) {
      width: 66.667vw;
      margin: 0 auto;
      padding: 5.333vw 6.667vw;
    }

    &--head {
      @include mq(md) {
        margin-bottom: 4vw;
      }
    }

    &--wrap {
      @include mq(md) {
        width: 41.733vw;
        margin-right: 2.4vw;
      }
    }

    &--title {
      margin-bottom: get_vw(10);
      letter-spacing: 3px;
      font-size: get_vw(26);
      color: $mainColor;

      @include mq(md) {
        margin-bottom: 1.4vw;
        font-size: 1.867vw;
      }

      &__lg {
        font-size: 3.6rem;

        @include mq(md) {
          font-size: 2.4vw;
        }
      }
    }

    &--head {
      @include mq(md) {
        display: flex;
      }
    }

    &__text {
      margin-bottom: get_vw(10);
      letter-spacing: 1.5px;
      font-size: get_vw(16);
      line-height: 3rem;

      @include mq(md) {
        margin-bottom: 0;
        font-size: 1.067vw;
      }
    }
  }

  &__img {
    margin-bottom: get_vw(20);
    text-align: center;

    @include mq(md) {
      width: 22.333vw;
      margin-bottom: 0;
    }
  }

  &__address {
    margin-bottom: get_vw(60);
    padding: get_vw(20) get_vw(10);
    font-style: normal;
    background: $baseBg;

    @include mq(md) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4vw;
      padding: 2vw;
      padding-bottom: 1vw;
    }

    &--item {
      font-size: get_vw;

      @include mq(md) {
        font-size: 1.067vw;
      }
    }

    &--img {
      text-align: right;

      @include mq(md) {
        position: relative;
        bottom: 1rem;
      }

      img {
        width: get_vw(100);
        @include mq(md) {
          width: 8.333vw;
          height: 5vw;
        }
      }
    }

    &--bold {
      font-weight: bold;
    }
  }
}
