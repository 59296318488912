@charset "UTF-8";
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;

.c-button {
  width: 100%;

  &__primary {
    display: block;
    box-sizing: border-box;
    // padding: 3rem 8rem;
    max-width: 38rem;
    height: 8rem;
    margin: 0 auto;
    text-align: center;
    letter-spacing: 2px;
    font-size: 2.2rem;
    line-height: 8rem;
    border-radius: 0.5rem;
    background: $mainColor;
    background-image: -webkit-linear-gradient(left, transparent 50%, $accentColor 50%);
    background-image: linear-gradient(to right, transparent 50%, $accentColor 50%);
    background-size: 200% 100%;
    color: $baseBg;
    -webkit-transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
      color 0.5s ease 0s, background-color 0.5s ease;
    transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s, color 0.5s ease 0s,
      background-color 0.5s;

    &--icon {
      position: relative;
      top: 3rem;
      left: 1.5rem;

      @include mq(md) {
        left: 6rem;
      }
    }

    &:hover {
      background-color: $accentColor;
      background-position: -100% 100%;
    }
  }

  &__top {
    margin-top: 2rem;
    background: $accentColor;
    background-image: -webkit-linear-gradient(left, transparent 50%, $baseBg 50%);
    background-image: linear-gradient(to right, transparent 50%, $baseBg 50%);
    background-size: 200% 100%;
    color: $mainColor;
    -webkit-transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
      color 0.5s ease 0s, background-color 0.5s ease;
    transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s, color 0.5s ease 0s,
      background-color 0.5s;

    &:hover {
      background-color: $baseBg;
      background-position: -100% 100%;
      cursor: pointer;
    }
  }
}
