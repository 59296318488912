@charset "UTF-8";
@use "../foundation/variable" as *;
@use "../foundation/mixin" as *;

.p-header {
  width: 100%;
  background: $subBg;
  transition: all 0.5s;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    height: 8rem;
    padding: 0 1rem;
    background: $subBg;
    opacity: 1;

    @include mq(md) {
      padding: 0 3rem;
    }
  }

  &__logo--company {
    position: relative;
    margin-right: 6vw;

    @include mq(md) {
      margin-right: 3vw;
    }

    img {
      width: 18vw;
      max-width: 9rem;
    }
  }

  &__logo--shopify {
    img {
      vertical-align: bottom;
      width: 47vw;
      max-width: 22.4rem;
    }
  }

  &__button {
    &--contact__pc {
      display: none;

      @include mq(md) {
        display: block;
        position: relative;
        right: 4vw;
        width: 20rem;
        height: 6rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 6rem;
        border-radius: 5px;
        background: $mainColor;
        background-image: -webkit-linear-gradient(left, transparent 50%, $accentColor 50%);
        background-image: linear-gradient(to right, transparent 50%, $accentColor 50%);
        background-size: 200% 100%;
        color: $accentColor;
        -webkit-transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
          color 0.5s ease 0s, background-color 0.5s ease;
        transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s, color 0.5s ease 0s,
          background-color 0.5s;

        &:hover {
          background-color: $accentColor;
          background-position: -100% 100%;
          color: $mainColor;
        }
      }
    }

    &--image {
      position: relative;
      right: 6vw;

      @include mq(md) {
        display: none;
      }
    }
  }
}

.header-animation {
  z-index: 990;
  background: #fff;
  transition: all 0.5s;
}
