@charset "UTF-8";
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;

/* ========================================
  u-indent
======================================== */

.u-indent {
  padding-left: 2em;
  text-indent: -2em;

  @include mq(md) {
    padding-left: 2vw;
    text-indent: -2vw;
  }
}

.u-indent1 {
  padding-left: 2.2rem;
}

.u-indent2 {
  padding-left: 1em;
  text-indent: -1em;
}
