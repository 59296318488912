@charset "UTF-8";
@use "../foundation/variable" as *;
@use "../foundation/mixin" as *;

.l-main {
  margin-top: 80px;
}

.l-container {
  padding: 0 2rem;

  @include mq(md) {
    padding: 0;
  }
}
